import { path } from 'ramda'

import { IAgeData, IPagination, IPayment } from './Payments.types'

export const paymentsLoadedSelector = path<boolean>(['payments', 'paymentsLoaded'])
export const paymentsFailedSelector = path<boolean>(['payments', 'paymentsFailed'])
export const paymentsSelector = path<IPayment[]>(['payments', 'payments'])
export const paginationSelector = path<IPagination>(['payments', 'pagination'])
export const hasAktivtAgeArendeSelector = path<boolean>(['payments', 'hasAktivtAgeArende'])
export const ageDataSelector = path<IAgeData>(['payments', 'age'])
export const isAgeLoadingSelector = path<boolean>(['payments', 'isAgeLoading'])
