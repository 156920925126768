export interface IConfiguration {
	API_URL: string
	MEDIA_URL: string
	AUTHORITY_URL: string
	AUTHORITY_CLIENT_ID: string
	ADVISOR_SESSION_KEY: string
	ENVIRONMENT: string
	REPLACEABLE?: string
}

// This configuration will be used for local development
const compiletimeConfiguration = {
	API_URL: process.env.API_URL,
	MEDIA_URL: process.env.MEDIA_URL,
	AUTHORITY_URL: process.env.AUTHORITY_URL,
	AUTHORITY_CLIENT_ID: process.env.AUTHORITY_CLIENT_ID,
	ADVISOR_SESSION_KEY: process.env.ADVISOR_SESSION_KEY,
	ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
}

// This is the object that will be replaced in the release pipeline, do not change this
const APP_CONFIGURATION = {
	REPLACEABLE: 'CONFIG',
}

const getConfig = (): IConfiguration => ({
	...compiletimeConfiguration,
	...APP_CONFIGURATION,
})

export default getConfig
