import { Typography } from '@mui/material'
import React from 'react'

interface Props {
	content: string
}

export const PreliminarSkattInfo = ({ content }: Props) => (
	<Typography variant="body2" gutterBottom>
		{content}
	</Typography>
)
