import React from 'react'
import { Box, Typography } from '@mui/material'

interface Props {
	headerContent: string
	content: string
}

export const UtbetalningarAvAGE = ({ headerContent, content }: Props) =>
	headerContent &&
	content && (
		<Box>
			<Typography variant="h3">{headerContent}</Typography>
			<Typography variant="body2" gutterBottom>
				{content}
			</Typography>
		</Box>
	)
