/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { AnyAction } from 'redux'
import { handleActions } from 'redux-actions'

import {
	getAgeFailure,
	getAgeInitiate,
	getAgeSuccess,
	getPaymentsFailure,
	getPaymentsInitiate,
	getPaymentsSuccess,
	setPage,
} from './Payments.actions'
import { IPagination, IPayment } from './Payments.types'

export interface IPaymentsState {
	pagination: IPagination
	paymentsLoaded: boolean
	payments: IPayment[]
	paymentsFailed: boolean
}

export const initialState: IPaymentsState = {
	pagination: {
		currentPage: 1,
	},
	paymentsLoaded: false,
	payments: [],
	paymentsFailed: false,
}

export interface IAction<T> extends AnyAction {
	payload?: T
}

const paymentsReducer = handleActions(
	{
		[getPaymentsInitiate.toString()]: (state: IPaymentsState) => ({
			...state,
			paymentsLoaded: false,
			paymentsFailed: false,
		}),
		[getPaymentsSuccess.toString()]: (state: IPaymentsState, action: AnyAction) => ({
			...state,
			payments: action.payload.utbetalningsunderlag,
			pagination: action.payload.pagination,
			paymentsLoaded: true,
			paymentsFailed: false,
			hasAktivtAgeArende: action.payload.hasAktivtAgeArende,
		}),
		[getPaymentsFailure.toString()]: (state: IPaymentsState) => ({
			...state,
			paymentsLoaded: true,
			paymentsFailed: true,
		}),
		[setPage.toString()]: (state: IPaymentsState, action: AnyAction) => ({
			...state,
			pagination: {
				...state.pagination,
				currentPage: action.payload,
			},
		}),
		[getAgeInitiate.toString()]: (state) => ({
			...state,
			isAgeLoading: true,
		}),
		[getAgeSuccess.toString()]: (state, action) => ({
			...state,
			...action.payload,
			isAgeLoading: false,
		}),
		[getAgeFailure.toString()]: (state) => ({
			...state,
			isAgeLoading: false,
		}),
	},
	initialState
)

export default paymentsReducer
