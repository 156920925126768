/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment  */
import { compose, createStore, applyMiddleware, combineReducers, StoreEnhancer } from 'redux'
import thunk from 'redux-thunk'
import payments from '@local/Payments/Payments.reducers'

import { IPagination, IPayment } from '../Payments/Payments.types'

declare global {
	// eslint-disable-next-line
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
	}
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reduxDevToolsOptions: any = {}

const composeEnhancers =
	typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(reduxDevToolsOptions)
		: compose

const enhancer = composeEnhancers(applyMiddleware(thunk))
const rootReducer = combineReducers({ payments })

const configureStore = (preloadedState?: {
	payments: {
		payments: IPayment[]
		pagination: IPagination
		paymentsLoaded: boolean
		paymentsFailed: boolean
	}
}) => createStore(rootReducer, preloadedState, enhancer as StoreEnhancer<unknown, Record<string, unknown>>)

const store = configureStore()

export default store
