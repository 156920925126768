import React from 'react'
import { Stack } from '@mui/material'

import { IPayment, IPaymentContent } from '../Payments.types'
import { hasPaymentTypeBruttoAndSkatt, hasPaymentTypeKrPerVecka, hasPaymentTypeNoticeDetails } from '../Payments.helpers'

import { Brutto, Skatt, NoticeDetails, KrPerVecka } from './Expanded'
import { Netto } from './Common'

export const PaymentsItemExpandedContent = (payment: IPayment, content: IPaymentContent) => {
	const shouldRenderBruttoAndSkatt = hasPaymentTypeBruttoAndSkatt(payment.typ)
	const shouldRenderKrPerVecka = payment?.kompletterandeStudiestodUnderlag && hasPaymentTypeKrPerVecka(payment.typ)
	const shouldRenderNoticeDetails = hasPaymentTypeNoticeDetails(payment.typ)

	return (
		<Stack spacing={1}>
			{shouldRenderKrPerVecka && (
				<KrPerVecka belopp={payment.kompletterandeStudiestodUnderlag.krPerVecka} content={content} type={payment.typ} />
			)}

			{shouldRenderBruttoAndSkatt && (
				<Stack spacing={1}>
					<Brutto bruttobelopp={payment.bruttobelopp} type={payment.typ} content={content} />
					<Skatt skatteavdrag={payment.skatteavdrag} skattesats={payment.skattesats} type={payment.typ} content={content} />
				</Stack>
			)}

			<Netto nettobelopp={payment.nettobelopp} type={payment.typ} content={content} expanded />

			{shouldRenderNoticeDetails && <NoticeDetails content={content} type={payment.typ} />}
		</Stack>
	)
}
