import React from 'react'
import { Alert, Box } from '@mui/material'

interface Props {
	content: string
}

export const UtbetalningstidInfoBox = ({ content }: Props) => (
	<Box mb={2}>
		<Alert severity="info">{content}</Alert>
	</Box>
)
