import { concat } from 'ramda'

import { IPayment, PaymentType, UtbetalningsPeriodEnum } from './Payments.types'

export const getPageFromUrlParams = (url: string): number => {
	const pageParam = parseInt(url.split('?page=').pop(), 10)
	return pageParam ? pageParam : 1
}

export const buildPaginationString = (page = 1): string => `?page=${page}`

export const buildUtbetalningsperiodTypeString = (isGenomfordaUtbetalningar: boolean) => {
	const type: UtbetalningsPeriodEnum = isGenomfordaUtbetalningar ? UtbetalningsPeriodEnum.GENOMFORDA : UtbetalningsPeriodEnum.KOMMANDE
	return `&utbetalningsperiod=${type}`
}

export const buildQuery = (page: number, isGenomfordaUtbetalningar: boolean): string =>
	concat(buildPaginationString(page), buildUtbetalningsperiodTypeString(isGenomfordaUtbetalningar))

export const isKksAterbetalning = (paymentType: PaymentType) =>
	paymentType === 'kksaterbetalningbrutto' || paymentType === 'kksaterbetalningnetto'

export const getPaymentType = (payment: IPayment): PaymentType => {
	const kksUnderlagstyp = payment?.kompletterandeStudiestodUnderlag?.underlagstyp

	switch (kksUnderlagstyp) {
		case 'aterbetalningbrutto':
			return 'kksaterbetalningbrutto'
		case 'aterbetalningnetto':
			return 'kksaterbetalningnetto'
		case 'avbrutenutbetalning':
			return 'kksavbrutenutbetalning'
		default:
			return payment.typ
	}
}
