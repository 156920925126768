/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { get } from '@local/Utils/Network'
import { createActions } from 'redux-actions'
import { Authentication } from '@trr/app-shell-data'

import { IArendeData, IPayment } from './Payments.types'

export const { getPaymentsInitiate, getPaymentsSuccess, getPaymentsFailure, setPage } = createActions({
	setPage: (page: number) => page,
	getPaymentsInitiate: () => ({}),
	getPaymentsSuccess: (data: IPayment[]) => data,
	getPaymentsFailure: () => ({}),
})

export const { getAgeInitiate, getAgeSuccess, getAgeFailure } = createActions({
	getAgeInitiate: (reload: boolean) => reload,
	getAgeSuccess: (data: IArendeData) => data,
	getAgeFailure: () => ({}),
})

export const getPayments = ({ klientId, query = '', klient }: { klientId: string; query?: string; klient: Authentication }) =>
	get({
		initiated: getPaymentsInitiate,
		fulfilled: getPaymentsSuccess,
		rejected: getPaymentsFailure,
		url: `/ekonomisktstod/utbetalningsunderlag/klient/${klientId}${query}`,
		klient: klient,
	})

export const getAge = () =>
	get({
		initiated: () => getAgeInitiate,
		fulfilled: getAgeSuccess,
		rejected: getAgeFailure,
		url: '/ekonomisktstod/klient',
		appendUrl: '/age',
		appendSub: true,
	})
