import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from '@local/Store/storeContext'
import { paginationSelector, hasAktivtAgeArendeSelector, paymentsFailedSelector } from '@local/Payments/Payments.selectors'
import { getPageFromUrlParams, buildQuery } from '@local/Payments/Payments.helpers'
import { useDevice, useEpiContent, useCurrentKey, useAuthentication } from '@trr/app-shell-data'
import { useHistory } from 'react-router-dom'
import { useDidMount } from '@local/Utils/Hooks'

import { IPaymentContent } from '../Payments/Payments.types'
import { getPayments, setPage } from '../Payments/Payments.actions'
import Payments from '../Payments'
import { PreliminarSkattInfo } from '../Payments/Fragments/Common/PreliminarSkattInfo'
import { UtbetalningarAvAGE } from '../Payments/Fragments/Common/UtbetalningarAvAGE'
import { UtbetalningstidInfoBox } from '../Payments/Fragments/Common/UtbetalningstidInfoBox'
import PaymentInfoBlock from '../Payments/PaymentInfoBlock'
import { objectFirstLevelToLowerCase } from '../Utils/Helpers'

const PaymentsFeedTab = () => {
	const dispatch = useDispatch()
	const paymentsFailed = useSelector(paymentsFailedSelector)
	const history = useHistory()
	const pagination = useSelector(paginationSelector)
	const isFirstRender = !useDidMount()
	const { isMobile } = useDevice()
	const content = useEpiContent()
	const currentKey = useCurrentKey()
	const hasAktivtAgeArende = useSelector(hasAktivtAgeArendeSelector)
	const kommandeUtbetalningarKey = 'kommande-utbetalningar'
	const genomfordaUtbetalningarKey = 'genomforda-utbetalningar'
	const klient = useAuthentication()

	const getUtbetalningarContent = (key: string): IPaymentContent =>
		objectFirstLevelToLowerCase(
			content[key === kommandeUtbetalningarKey ? 'kommandeutbetalningar' : 'genomfordautbetalningar']
		) as unknown as IPaymentContent

	const utbetalningarContent = getUtbetalningarContent(currentKey)

	useEffect(() => {
		// Sets page and get payments by url query on first load
		if (isFirstRender) {
			const query = getPageFromUrlParams(window.location.search)
			dispatch(setPage(query))
			return
		}
		// When component mounted, update history url and get payments by page query params
		const isGenomfordaUtbetalningar = currentKey === genomfordaUtbetalningarKey
		const query = buildQuery(pagination.currentPage, isGenomfordaUtbetalningar)

		history.push(query)
		dispatch(
			getPayments({
				klientId: klient.sub,
				query,
				klient: klient,
			})
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination.currentPage, dispatch, history, isFirstRender, klient.sub, currentKey])

	if (paymentsFailed) {
		return <div data-testid="tab-wrapper-error">Något gick fel.</div>
	}

	return (
		<Fragment>
			<PaymentInfoBlock content={utbetalningarContent} />

			{hasAktivtAgeArende && (
				<UtbetalningarAvAGE
					headerContent={utbetalningarContent.utbetalningaravageheader}
					content={utbetalningarContent.utbetalningaravagetext}
				/>
			)}
			<UtbetalningstidInfoBox content={utbetalningarContent.utbetalningstidinfoboxtext} />
			<PreliminarSkattInfo content={utbetalningarContent.preliminarskattinfotext} />
			<Payments device={isMobile} content={utbetalningarContent} />
		</Fragment>
	)
}

export default PaymentsFeedTab
