import { Alert, Snackbar } from '@mui/material'
import React from 'react'

type SeverityTypes = 'success' | 'info' | 'warning' | 'error'

interface ISystemFeedback {
	isVisible: boolean
	message: string
	severity: SeverityTypes
}

const SystemFeedback = ({ isVisible, message, severity }: ISystemFeedback) => {
	const [open, setOpen] = React.useState(isVisible)

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return
		}

		setOpen(false)
	}

	if (!message) return <></>

	return (
		<Snackbar open={open} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
			<Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
				{message}
			</Alert>
		</Snackbar>
	)
}

export default SystemFeedback
