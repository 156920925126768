import React from 'react'
import { IPaymentContent, PaymentType, TerminType } from '@local/Payments/Payments.types'
import { Typography } from '@mui/material'

import { pickLabel } from '../Fragments.helpers'

const terminer: Record<TerminType, number> = {
	termin1: 1,
	termin2: 2,
	termin3: 3,
	termin4: 4,
}

interface Props {
	subTyp: TerminType
	type: PaymentType
	content: IPaymentContent
}

export const Termin = ({ subTyp, type, content }: Props) => {
	const label = pickLabel(content, type, 'terminText')

	return (
		<Typography component="span" variant="body2">
			{`${label} ${terminer[subTyp]}`}
		</Typography>
	)
}
