import React from 'react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { trrFormatOccasionDate } from '@local/Utils/Helpers/formatDate'
import { isKksAterbetalning } from '@local/src/Payments/Payments.helpers'
import { Box, Stack, Typography } from '@mui/material'

import { IPayment, IPaymentContent } from '../Payments.types'

import { Termin, UtbetaldaDagar, Veckor } from './Collapsed'
import { Netto } from './Common'

export const PaymentsItemCollapsedContent = (content: IPaymentContent, payment: IPayment) => {
	const shouldRenderUtbetaldaDagar =
		payment.typ === 'utbetalning' || payment.typ === 'aterbetalning' || payment.typ === 'avbrutenutbetalning'

	const shouldRenderTermin = payment.subTyp && (payment.typ === 'studielon' || payment.typ === 'studieersattning')

	const shouldRenderVeckor =
		payment.typ === 'kompletterandestudiestod' || payment.typ === 'kksavbrutenutbetalning' || isKksAterbetalning(payment.typ)

	const shouldRenderPipe = shouldRenderUtbetaldaDagar || shouldRenderTermin || shouldRenderVeckor

	return (
		<Box>
			<Netto type={payment.typ} content={content} nettobelopp={payment.nettobelopp} />

			<Stack direction="row" spacing={1} mt={2}>
				<CalendarMonthIcon fontSize="small" />

				<Typography variant="body2">{trrFormatOccasionDate(payment.planeratUtbetalningsdatum).toString()}</Typography>

				{shouldRenderPipe && <Typography variant="body2">|</Typography>}

				{shouldRenderVeckor && <Veckor kompletterandeStudiestod={payment.kompletterandeStudiestodUnderlag} />}
				{shouldRenderUtbetaldaDagar && <UtbetaldaDagar type={payment.typ} content={content} utbetaldaDagar={payment.utbetaldaDagar} />}
				{shouldRenderTermin && <Termin type={payment.typ} content={content} subTyp={payment.subTyp} />}
			</Stack>
		</Box>
	)
}
