import React, { useEffect } from 'react'
import { HTMLMapper } from '@trr/html-mapper'

import { useDispatch, useSelector } from '../Store/storeContext'
import getConfig from '../Utils/getConfig'

import { IPaymentContent } from './Payments.types'
import { ageDataSelector } from './Payments.selectors'
import { getAge } from './Payments.actions'

interface InfoBlockProps {
	content: IPaymentContent
}

const PaymentInfoBlock = ({ content }: InfoBlockProps) => {
	const dispatch = useDispatch()
	const ageData = useSelector(ageDataSelector)
	const hasPeriodWithNoAge = ageData?.mojligaAgePerioder.some((period) => period.dagPenning === 0)

	useEffect(() => {
		dispatch(getAge())
	}, [dispatch])

	if (!hasPeriodWithNoAge) return <></>

	return <HTMLMapper body={content.informationnollkrperdaginfotext} mediaUrl={getConfig().MEDIA_URL} />
}

export default PaymentInfoBlock
