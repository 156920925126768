import { concat } from 'ramda'

import { IPayment, PaymentType, UtbetalningsPeriodEnum } from './Payments.types'

export const getPageFromUrlParams = (url: string): number => {
	const pageParam = parseInt(url.split('?page=').pop(), 10)
	return pageParam ? pageParam : 1
}

export const buildPaginationString = (page = 1): string => `?page=${page}`

export const buildUtbetalningsperiodTypeString = (isGenomfordaUtbetalningar: boolean) => {
	const type: UtbetalningsPeriodEnum = isGenomfordaUtbetalningar ? UtbetalningsPeriodEnum.GENOMFORDA : UtbetalningsPeriodEnum.KOMMANDE
	return `&utbetalningsperiod=${type}`
}

export const buildQuery = (page: number, isGenomfordaUtbetalningar: boolean): string =>
	concat(buildPaginationString(page), buildUtbetalningsperiodTypeString(isGenomfordaUtbetalningar))

export const getPaymentType = (payment: IPayment): PaymentType => {
	const kksUnderlagstyp = payment?.kompletterandeStudiestodUnderlag?.underlagstyp

	switch (kksUnderlagstyp) {
		case 'aterbetalningbrutto':
			return 'kksaterbetalningbrutto'
		case 'aterbetalningnetto':
			return 'kksaterbetalningnetto'
		case 'avbrutenutbetalning':
			return 'kksavbrutenutbetalning'
		case 'avdrag':
			return 'kksavdrag'
		default:
			return payment.typ
	}
}

export const hasPaymentTypeWeekValues = (paymentType: PaymentType) => {
	switch (paymentType) {
		case 'kompletterandestudiestod':
		case 'kksavbrutenutbetalning':
		case 'kksavdrag':
		case 'kksaterbetalningbrutto':
		case 'kksaterbetalningnetto':
			return true
		default:
			return false
	}
}

export const hasPaymentTypeNoticeDetails = (paymentType: PaymentType) => {
	switch (paymentType) {
		case 'lonetillagg':
		case 'aterbetalning':
		case 'kompletterandestudiestod':
		case 'avbrutenutbetalning':
		case 'kksaterbetalningbrutto':
		case 'kksaterbetalningnetto':
			return false
		default:
			return true
	}
}

export const hasPaymentTypeBruttoAndSkatt = (paymentType: PaymentType) => {
	switch (paymentType) {
		case 'aterbetalning':
		case 'avbrutenutbetalning':
		case 'kksaterbetalningbrutto':
		case 'kksaterbetalningnetto':
			return false
		default:
			return true
	}
}

export const hasPaymentTypeKrPerVecka = (paymentType: PaymentType) => {
	switch (paymentType) {
		case 'kksavdrag':
		case 'kksaterbetalningbrutto':
		case 'kksaterbetalningnetto':
			return false
		default:
			return true
	}
}

export const getContentstringByPaymentType = (paymentType: PaymentType, expanded?: boolean) => {
	switch (paymentType) {
		case 'kksavdrag':
		case 'loneavdrag':
			return expanded ? 'deductedAmountDetails' : 'deductedAmount'
		case 'aterbetalning':
		case 'avbrutenutbetalning':
		case 'kksaterbetalningbrutto':
		case 'kksaterbetalningnetto':
			return expanded ? 'amountDetails' : 'amount'
		default:
			return expanded ? 'paidAmountDetails' : 'paidAmount'
	}
}
