import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from '@local/Store/storeContext'
import { setPage } from '@local/Payments/Payments.actions'
import { withHooks } from '@local/Utils/Hooks'
import { paymentsSelector, paginationSelector, paymentsLoadedSelector } from '@local/Payments/Payments.selectors'

import Payments from './Payments'

const PaymentSelectors = () => ({
	payments: useSelector(paymentsSelector),
	paymentsLoaded: useSelector(paymentsLoadedSelector),
	pagination: useSelector(paginationSelector),
})

const PaymentsActions = () => {
	const dispatch = useDispatch()
	return bindActionCreators(
		{
			setPage,
		},
		dispatch
	)
}

export default withHooks({
	PaymentSelectors,
	PaymentsActions,
})(Payments)
