import React from 'react'
import { IPaymentContent, PaymentType } from '@local/Payments/Payments.types'
import { Typography } from '@mui/material'

import { pickLabel } from '../Fragments.helpers'

interface Props {
	utbetaldaDagar: number
	type: PaymentType
	content: IPaymentContent
}

export const UtbetaldaDagar = ({ utbetaldaDagar, type, content }: Props) => {
	const contentString = type === 'utbetalning' ? 'paidDaysDetails' : 'daysDetails'
	const label = pickLabel(content, type, contentString)

	return <Typography variant="body2">{`${label} ${utbetaldaDagar}`}</Typography>
}
