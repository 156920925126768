import React, { useMemo } from 'react'
import { IPaymentContent, PaymentType } from '@local/Payments/Payments.types'
import { formatCurrencySEK } from '@local/Utils/Helpers'
import { Grid2 as Grid, Typography } from '@mui/material'

import { pickLabel } from '../Fragments.helpers'
import { getContentstringByPaymentType } from '../../Payments.helpers'

interface Props {
	type: PaymentType
	content: IPaymentContent
	nettobelopp: number
	expanded?: boolean
}

export const Netto = ({ type, content, nettobelopp, expanded }: Props) => {
	const contentString = useMemo(() => getContentstringByPaymentType(type, expanded), [type, expanded])
	const label = useMemo(() => pickLabel(content, type, contentString), [content, type, contentString])

	return expanded ? (
		<Grid container rowSpacing={2}>
			<Grid size={{ xs: 4, sm: 2 }} pr={1}>
				<Typography variant="body2">{label}</Typography>
			</Grid>
			<Grid size={{ xs: 'auto' }}>
				<Typography variant="body2">{` ${formatCurrencySEK(Math.abs(nettobelopp))} *`}</Typography>
			</Grid>
		</Grid>
	) : (
		<Typography variant="body2" data-testid="netto">
			{label}
			<b>{` ${formatCurrencySEK(Math.abs(nettobelopp))} *`}</b>
		</Typography>
	)
}
