import React from 'react'
import { Stack } from '@mui/material'

import { IPayment, IPaymentContent } from '../Payments.types'
import { isKksAterbetalning } from '../Payments.helpers'

import { Brutto, Skatt, NoticeDetails, KrPerVecka } from './Expanded'
import { Netto } from './Common'

export const PaymentsItemExpandedContent = (payment: IPayment, content: IPaymentContent) => {
	const isTypeKksAterbetalning = isKksAterbetalning(payment.typ)
	const shouldRenderBruttoAndSkatt = payment.typ !== 'aterbetalning' && payment.typ !== 'avbrutenutbetalning' && !isTypeKksAterbetalning

	const shouldRenderKrPerVecka = payment?.kompletterandeStudiestodUnderlag && !isTypeKksAterbetalning

	const shouldRenderNoticeDetails =
		payment.typ !== 'lonetillagg' &&
		payment.typ !== 'aterbetalning' &&
		payment.typ !== 'kompletterandestudiestod' &&
		payment.typ !== 'avbrutenutbetalning' &&
		!isTypeKksAterbetalning

	return (
		<Stack spacing={1}>
			{shouldRenderKrPerVecka && (
				<KrPerVecka belopp={payment.kompletterandeStudiestodUnderlag.krPerVecka} content={content} type={payment.typ} />
			)}

			{shouldRenderBruttoAndSkatt && (
				<Stack spacing={1}>
					<Brutto bruttobelopp={payment.bruttobelopp} type={payment.typ} content={content} />
					<Skatt skatteavdrag={payment.skatteavdrag} skattesats={payment.skattesats} type={payment.typ} content={content} />
				</Stack>
			)}

			<Netto nettobelopp={payment.nettobelopp} type={payment.typ} content={content} expanded />

			{shouldRenderNoticeDetails && <NoticeDetails content={content} type={payment.typ} />}
		</Stack>
	)
}
