import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import sv from 'date-fns/locale/sv'

type trrDateType = (date?: Date | string) => Date

const stockholmTimeZone = 'Europe/Stockholm'

export const trrDate = (date: Date | string): Date => utcToZonedTime(date ? date : new Date(), stockholmTimeZone)

export const trrDateUTC: trrDateType = (date) => zonedTimeToUtc(date, stockholmTimeZone)

export const baseTrrFormat =
	(formatStr: string) =>
	(date: Date | string = new Date()): Date | string =>
		format(trrDate(date), formatStr, {
			timeZone: stockholmTimeZone,
			locale: sv,
		})

export const trrFormat = baseTrrFormat('yyyy-MM-dd')
export const trrFormatOccasionDate = baseTrrFormat('d MMM yyyy')
export const trrFormatTime = baseTrrFormat('H:mm')
export const trrFormatMeetingDateSummary = baseTrrFormat('EEEE d MMMM yyyy')
export const trrFormatMeetingTimeSummary = baseTrrFormat('HH:mm')
