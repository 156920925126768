import React, { Fragment } from 'react'

import { IPayment, IPaymentContent } from '../Payments.types'

import { pickLabel } from './Fragments.helpers'

const getPaymentHeaderKey = (payment: IPayment) =>
	payment?.typ === 'kompletterandestudiestod' && payment?.kompletterandeStudiestodUnderlag?.underlagstyp === 'tillagg'
		? 'subtypeHeader'
		: 'header'

export const PaymentsItemHeader = (payment: IPayment, content: IPaymentContent) => (
	<Fragment>{pickLabel(content, payment.typ, getPaymentHeaderKey(payment))}</Fragment>
)
