// will convert all the keys on the first level to lower case
export const objectFirstLevelToLowerCase = (
	// Wanna be able to pass any object
	/* eslint-disable-next-line */
	object: Record<string, any>
): Record<string, unknown> =>
	Object.keys(object).reduce((destination: Record<string, unknown>, key: string) => {
		destination[key.toLowerCase()] = object[key]
		return destination
	}, {})

type MapToOutput<I, U = I> = (v: I) => U

export const formatCurrencySEK: MapToOutput<number, string> = (amount) =>
	new Intl.NumberFormat('sv-se', {
		style: 'currency',
		currency: 'SEK',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	})
		.format(amount)
		//eslint-disable-next-line
		.replace(/\,00([^\d])/g, '$1') // Edge bug where minimumFractionDigits is ignored

export const pickNumbersFromString = (input: string): string => input?.replace(/\D/g, '')
