import React from 'react'
import { IPaymentContent, PaymentType } from '@local/Payments/Payments.types'
import { Box, Typography } from '@mui/material'

import { pickLabel } from '../Fragments.helpers'

interface Props {
	content: IPaymentContent
	type: PaymentType
}

export const NoticeDetails = ({ content, type }: Props) => {
	const label = pickLabel(content, type, 'noticeDetails')

	return (
		<Box sx={{ fontStyle: 'italic' }} mt={3}>
			<Typography variant="body2">{label}</Typography>
		</Box>
	)
}
