import React from 'react'
import { IKompletterandeStudiestod } from '@local/Payments/Payments.types'
import { addWeeks, differenceInCalendarISOWeeks } from 'date-fns'
import { Typography } from '@mui/material'

interface Props {
	kompletterandeStudiestod: IKompletterandeStudiestod
}

export const Veckor = ({ kompletterandeStudiestod: { slutVecka, startVecka, startAr, slutAr } }: Props) => {
	const startDate = addWeeks(new Date(Number(startAr), 0, 1), Number(startVecka))
	const endDate = addWeeks(new Date(Number(slutAr), 0, 1), Number(slutVecka))

	const amountOfWeeks = differenceInCalendarISOWeeks(endDate, startDate) + 1

	const weeksString = `${amountOfWeeks} veckor, v.${startVecka} ${startAr} - v.${slutVecka} ${slutAr}`

	return (
		<Typography component="span" variant="body2">
			{weeksString}
		</Typography>
	)
}
