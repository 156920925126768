import { useRef, useEffect } from 'react'

const useDidMount = (): boolean => {
	const isMountingRef = useRef<boolean>(false)
	useEffect(() => {
		isMountingRef.current = true
	}, [])
	return isMountingRef.current
}

export default useDidMount
