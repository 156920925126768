import { IDevice } from '@local/src/App.types'

export interface IPayments {
	pagination?: IPagination
	paymentsLoaded?: boolean
	device: IDevice
	payments?: IPayment[]
	setPage?: (page: number) => void
	content: IPaymentContent
}

export interface IUtbetalningarContent {
	genomfordautbetalningar?: IPaymentContent
	kommandeutbetalningar?: IPaymentContent
}
export interface IPaymentContentTypes {
	lonetillagg: IPaymentSharedContent & ILonetillaggContent
	utbetalning: IPaymentSharedContent & IUtbetalningContent
	loneavdrag: IPaymentSharedContent & IAvdragContent
	flyttkostnad: IPaymentSharedContent & IUtbetalningContent
	aterbetalning: IPaymentSharedContent & IAterbetalningContent
	avbrutenutbetalning: IPaymentSharedContent & IAterbetalningContent
	enstakaresor: IPaymentSharedContent & IUtbetalningContent
	konsultkostnad: IPaymentSharedContent & IUtbetalningContent
	kontantersattning: IPaymentSharedContent & IUtbetalningContent
	logi: IPaymentSharedContent & IUtbetalningContent
	loneutfyllnad: IPaymentSharedContent & IUtbetalningContent
	praktiklon: IPaymentSharedContent & IUtbetalningContent
	privatutlagg: IPaymentSharedContent & IUtbetalningContent
	programvara: IPaymentSharedContent & IUtbetalningContent
	regelbundenresa: IPaymentSharedContent & IUtbetalningContent
	startlon: IPaymentSharedContent & IUtbetalningContent
	utbildningskostnad: IPaymentSharedContent & IUtbetalningContent
	studielon: IPaymentSharedContent & IUtbetalningContent & ITerminContent
	studieersattning: IPaymentSharedContent & IUtbetalningContent & ITerminContent
	kompletterandestudiestod: IPaymentSharedContent & IUtbetalningContent
	kortvarigtstudiestod: IPaymentSharedContent & IUtbetalningContent
	kksaterbetalningnetto: IPaymentSharedContent & IAterbetalningContent
	kksaterbetalningbrutto: IPaymentSharedContent & IAterbetalningContent
	kksavbrutenutbetalning: IPaymentSharedContent & IUtbetalningContent
	kksavdrag: IPaymentSharedContent & IAvdragContent
}
export interface IPaymentContent extends IPaymentContentTypes {
	urlKey: string
	emptylisttitle: string
	emptylistbody: string
	preliminarskattinfotext: string
	utbetalningstidinfoboxtext: string
	utbetalningaravagetext: string
	utbetalningaravageheader: string
	underhallsinformationtext?: string
	underhallsinformationtyp?: 'info' | 'success' | 'error' | 'warning'
	informationnollkrperdaginfotext: string
}

interface ITerminContent {
	terminText: string
}

export interface IPaymentSharedContent {
	beforeTaxDetails: string
	header: string
	notice: string
	taxDetails: string
}

interface IAvdragContent {
	noticeDetails: string
	deductedAmount: string
	deductedAmountDetails: string
}

interface ILonetillaggContent {
	paidAmount?: string
	paidAmountDetails?: string
}

interface IUtbetalningContent {
	paidAmount: string
	paidAmountDetails: string
	paidDaysDetails: string
	noticeDetails?: string
	amountPerWeekDetails: string
	subtypeHeader: string
}

interface IAterbetalningContent {
	amount: string
	amountDetails: string
	daysDetails: string
	header: string
	notice: string
}

export interface IKompletterandeStudiestod {
	startAr: string
	slutAr: string
	startVecka: string
	slutVecka: string
	krPerVecka: number
	underlagstyp: UnderlagsTyp
}

export type UnderlagsTyp = 'tillagg' | 'standard' | 'aterbetalningbrutto' | 'aterbetalningnetto' | 'avbrutenutbetalning' | 'avdrag'

export interface IPayment {
	bruttobelopp: number
	godkandDatum: string
	planeratUtbetalningsdatum: string
	nettobelopp: number
	skatteavdrag: number
	skattesats: number
	typ: PaymentType
	utbetaldaDagar?: number
	subTyp?: TerminType
	kompletterandeStudiestodUnderlag?: IKompletterandeStudiestod | null
}

export type PaymentType = keyof IPaymentContentTypes

export interface IPagination {
	currentPage?: number
	totalPages?: number
}

export type TerminType = TerminEnum | null
export enum TerminEnum {
	TERMIN1 = 'termin1',
	TERMIN2 = 'termin2',
	TERMIN3 = 'termin3',
	TERMIN4 = 'termin4',
}

export type UtbetalningsperiodType = UtbetalningsPeriodEnum
export enum UtbetalningsPeriodEnum {
	KOMMANDE = 'kommande',
	GENOMFORDA = 'genomforda',
}

export interface IArendeData {
	age: IAgeData
	arendeNummer: string
	harVaritAktivtArbetssokande: boolean
	projektId: string
	klientId: string
	orsakAnsokan: string
}

export interface IAgeData {
	aktivt: boolean
	avslutOrsak: string
	efterskyddStartDatum: string | Date
	dagarTillUtbetalning: number
	harInteAkassa: boolean
	kanAvslutaStartatEgetForetag: boolean
	kanIntygaArbetssokandeAkassa: boolean
	kanIntygaArbetssokandeArbetsformedling: boolean
	kanSkrivaUtAgeBeslut: boolean
	maxDagsBelopp: number
	maxErsattningsDagar: number
	mojligaAgePerioder: [
		{
			dagPenning: number
			slutDag: number
			startDag: number
		},
	]
	slutDatum: string | Date
	startDatum: string | Date
	startatEgetForetagDatum: string | Date
	startatEgetForetagPaArendet: boolean
	skattesats: number
	utbetaldaDagar: number
	villInteAngeAkassa: boolean
}
