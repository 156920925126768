import React from 'react'
import { IPaymentContent, PaymentType } from '@local/Payments/Payments.types'
import { formatCurrencySEK } from '@local/Utils/Helpers'
import { Grid2 as Grid, Typography } from '@mui/material'

import { pickLabel } from '../Fragments.helpers'

interface Props {
	belopp: number
	type: PaymentType
	content: IPaymentContent
}

export const KrPerVecka = ({ belopp, type, content }: Props) => {
	const label = pickLabel(content, type, 'amountPerWeekDetails')

	return (
		<Grid container mt={2} rowSpacing={2} data-testid="kr-per-vecka">
			<Grid size={{ xs: 4, sm: 2 }}>
				<Typography variant="body2">{label}</Typography>
			</Grid>
			<Grid size={{ xs: 'auto' }}>
				<Typography variant="body2">{` ${formatCurrencySEK(Math.abs(belopp))}`}</Typography>
			</Grid>
		</Grid>
	)
}
