import React from 'react'
import { getPaymentType } from '@local/src/Payments/Payments.helpers'
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import { PaymentsItemHeader, PaymentsItemCollapsedContent, PaymentsItemExpandedContent } from './Fragments'
import { IPayment, IPaymentContent } from './Payments.types'

export const PaymentsItem = ({ payment, content }: { payment: IPayment; content?: IPaymentContent }) => {
	const paymentWithUpdatedType: IPayment = { ...payment, typ: getPaymentType(payment) }
	const customEventTracking = useTrackCustomClick()

	const handleChange = (expanded: boolean) => {
		const trackingAction = expanded ? `öppnades` : `stängdes`

		customEventTracking('utbetalning', {
			label: trackingAction,
		})
	}

	return (
		<Box mb={2}>
			<Accordion data-testid="payments-item" onChange={(event, expanded) => handleChange(expanded)}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Stack>
						<Typography variant="h6" mb={2}>
							{PaymentsItemHeader(paymentWithUpdatedType, content)}
						</Typography>

						{PaymentsItemCollapsedContent(content, paymentWithUpdatedType)}
					</Stack>
				</AccordionSummary>
				<AccordionDetails>
					<Box ml={4}>{PaymentsItemExpandedContent(paymentWithUpdatedType, content)}</Box>
				</AccordionDetails>
			</Accordion>
		</Box>
	)
}
