import React from 'react'
import { Provider } from 'react-redux'
import { PaymentsFeedTab } from '@local/src/Pages'
import { createTheme, ThemeProvider } from '@mui/material'
import { themeOptions, defaultLocale } from '@trr/mui-theme'
import { GTMTracker } from '@trr/gtm-tracking'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'

import Store from './Store/store'
import { UtbetalningarContext } from './Store/storeContext'

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
	const theme = createTheme(themeOptions, defaultLocale)

	return (
		<ThemeProvider theme={theme}>
			<GTMTracker mfName="utbetalningar">
				<AppShellDataProvider value={appShellData}>
					<Provider store={Store} context={UtbetalningarContext}>
						<PaymentsFeedTab />
					</Provider>
				</AppShellDataProvider>
			</GTMTracker>
		</ThemeProvider>
	)
}

export default App
